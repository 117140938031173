import React from 'react';
import footerStyles from '../styles/footer.module.less';
// import Content from '../components/content';

export default () => {
    return (
        <>
            <footer>
                <section className={footerStyles.footerTop}>
                    <div className={footerStyles.footerContent}>
                        <dl>
                            <dt>官网跳转</dt>
                            <dd>
                                <a href="/">首页</a>
                            </dd>
                            <dd>
                                <a href="/">行业</a>
                            </dd>
                            <dd>
                                <a href="/">服务承诺</a>
                            </dd>
                            <dd>
                                <a href="/">渠道合作</a>
                            </dd>
                            <dd>
                                <a href="/">关于我们</a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>推荐链接</dt>
                            <dd>
                                <a href="https://www.quanerp.com">小圈ERP</a>
                            </dd>
                            <dd>
                                <a href="http://www.kuguanwang.com/">库管王</a>
                            </dd>
                            <dd>
                                <a href="http://www.gendanwang.com/">跟单王</a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>联系我们</dt>
                            <dd className={footerStyles.callPhone}>400-651-3115</dd>
                            <dd>marketing@kuguanwang.com</dd>
                            <dd>www.hikgw.com</dd>
                        </dl>
                    </div>
                </section>
                <p>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">沪ICP备16015734号-7</a>
                </p>
            </footer>
        </>
    );
};
