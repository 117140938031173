import axios from 'axios';

import message from 'antd/es/message';

const BASE_URL = 'https://jkucun.com';
// const BASE_URL = '';
const http = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});

http.interceptors.request.use(
    (config) => {
        config.headers['Content-Type'] = 'application/json-patch+json';
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => {
        const { data: responseData } = response;
        
        if (responseData.success || responseData.succeeded) {
            return responseData.result || responseData || true;
        }

        const { error } = responseData;
        if (error.message === '无可用企业，请联系管理员！') {
            return Promise.resolve(true);
        }
        message.error(error.message || '系统错误');
    },
    (error) => {
        
        const { status = 0 } = error.response || {};
        if (status === 401) {
            message.warn('当前用户没有权限');
            return;
        }
        if (status === 404) {
            console.error('无该接口地址!', '报错接口地址 ↓', error.response.config[0]);
            return;
        }
        Promise.reject(error);
        // return error;
    }
);

export default http;
