import { useEffect } from 'react';

export default () => {
    // return (
    //     <script
    //         dangerouslySetInnerHTML={{
    //             _html: `
    //               var _hmt = _hmt || [];
    //               (function () {
    //                   var hm = document.createElement("script");
    //                   hm.src = "https://hm.baidu.com/hm.js?f89c2676d8d66b82800524c1cee21d71";
    //                   var s = document.getElementsByTagName("script")[0];
    //                   s.parentNode.insertBefore(hm, s);
    //               })();
    //             `
    //         }}
    //     ></script>
    // );
    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        var _hmt = _hmt || [];
        (function() {
            var hm = document.createElement('script');
            hm.src = 'https://hm.baidu.com/hm.js?f89c2676d8d66b82800524c1cee21d71';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(hm, s);
        })();
    }, []);
    return null;
};
