import React from 'react';

export default (props) => {
    const { className = '', title, titleStyle, description, descriptionStyle, children, style } = props;
    const renderDescriotion = () => {
        return { __html: description };
    };

    const titleEl = title ? <h2 style={titleStyle}>{title}</h2> : null;
    const descriotionEl = description ? (
        <h4 style={descriptionStyle} dangerouslySetInnerHTML={renderDescriotion()}></h4>
    ) : null;
    return (
        <section
            className={`app-section ${className}`}
            style={style}
        >
            {titleEl}
            {descriotionEl}
            {children}
        </section>
    );
};
