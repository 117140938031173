import React, { useState, useEffect } from 'react';

import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import message from 'antd/es/message';

import { sendVerify, sendEmail, getExperience } from '../api/server';

export default (props) => {
    const MAX_COUNT_DOWN = 60; // 最大倒计时值

    const [showModal, setShowModal] = useState(false);
    const [countDownTime, setCountDownTime] = useState(MAX_COUNT_DOWN); // 倒计时时间
    const [countDownText, setCountDownText] = useState('验证码'); // 倒计时文本
    const [countDownLoading, setCountDownLoading] = useState(false); // 倒计时状态

    const [applyForm] = Form.useForm(); // 创建表单实例
    // 提交表单
    const handleFinish = async (values) => {
        const res = await getExperience({
            fullName: values.userName,
            userMobile: values.userMobile,
            code: values.code,
            clientName: values.clientName,
        });
        await sendEmail({
            fullName: values.userName,
            mobile: values.userMobile,
            clientName: values.clientName,
            to: 'wangyang@guojing.net',
            // to: 'elaine_mo96@hotmail.com',
            productName: '简库存'
        });
        if (res) {
            message.success('申请成功');
            var appUrl = 'https://jkucun.com/#/experienceIndustry';
            window.open(appUrl);
            props.onCancel(showModal);
            setShowModal(false); // 关闭弹窗
        } else {
            // message.error('验证码错误');
        }
        
    };

    const handleGetCaptchaCode = async () => {
        let params = {
            userMobile: applyForm.getFieldValue('userMobile')
        };
        const APPID = '2033336751';
        // eslint-disable-next-line no-undef
        const captcha = new TencentCaptcha(APPID, (res) => {
            if (res.ret === 0) {
                // 验证通过
                params = {
                    ...params,
                    ticket: res.ticket,
                    randstr: res.randstr,
                };
                sendCode(params);
            };
        }, {});
        if (applyForm.getFieldValue('userMobile')) {
            captcha.show();
            return;
        }
        captcha.destroy();
        message.warn('请填写合法的手机号');
        return;
    };

    // 发送验证码
    const sendCode = async (params) => {
        // 校验手机号
        const userMobile = applyForm.getFieldValue('userMobile');
        if (!userMobile || userMobile.length !== 11) {
            message.warn('请填写合法的手机号');
            return;
        }
        const res = await sendVerify(params);
        if (res) {
            setCountDownLoading(true);
            setCountDownText(`${countDownTime} s`);
            let interval;
            interval = setInterval(() => {
                setCountDownTime((second) => {
                    if (second <= 1) {
                        setCountDownLoading(false);
                        clearInterval(interval);
                        setCountDownText('验证码');
                        return MAX_COUNT_DOWN;
                    } else {
                        let secondNow = second - 1;
                        setCountDownText(`${secondNow} s`);
                        return secondNow;
                    }
                });
            }, 1000);
        }
    };

    // 处理取消
    const handleCancel = () => {
        setShowModal(false);
        props.onCancel(showModal);
    };

    useEffect(() => {
        setShowModal(props.showModal);
    }, [props.showModal]);

    return (
        <Modal visible={showModal} footer={null} title="申请体验" onCancel={handleCancel}>
            <Form
                onFinish={handleFinish}
                {...{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }}
                form={applyForm}
            >
                <Form.Item
                    label="企业名称"
                    name="clientName"
                    rules={[{ required: true, message: '请填写您的企业名称' }]}
                >
                    <Input placeholder="请填写企业名称" />
                </Form.Item>
                <Form.Item
                    label="联系人"
                    name="userName"
                    rules={[{ required: true, message: '请填写联系人' }]}
                >
                    <Input placeholder="请填写联系人" />
                </Form.Item>
                <Form.Item
                    label="联系电话"
                    name="userMobile"
                    rules={[
                        { required: true, message: '请填写联系电话' },
                        { pattern: /^1(3|4|5|6|7|8)\d{9}$/, message: '请填写合法的手机号' }
                    ]}
                >
                    <Input
                        placeholder="请填写联系电话"
                        suffix={
                            <Button
                                type="primary"
                                disabled={countDownLoading}
                                onClick={handleGetCaptchaCode}
                            >
                                {countDownText}
                            </Button>
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="验证码"
                    name="code"
                    rules={[{ required: true, message: '请填写验证码' }]}
                >
                    <Input placeholder="请填写验证码" />
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        立即体验
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};
