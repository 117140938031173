import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Button from 'antd/es/button';

import Icon from '../components/icon';

import headerStyles from '../styles/header.module.less';
import Fixwindow from '../components/fixwindow';
import androidImage from '../images/QRCode1.png';
import ExperienceModal from '../components/modal';

const navs = [
    {
        text: '首页',
        to: '/home/'
    },
    {
        text: '行业',
        to: '/industry'
    },
    {
        text: '服务承诺',
        to: '/service'
    },
    {
        text: '渠道合作',
        to: '/cooperate'
    },
    {
        text: '新闻资讯',
        to: '/news'
    },
    {
        text: '关于我们',
        to: '/about'
    }
];

export default () => {
    const [sticky, setSticky] = useState('');
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            const t = document.querySelector('body, html').scrollTop;

            if (t > 0) {
                if (sticky === '') setSticky(headerStyles.header__sticky);
            } else {
                setSticky('');
            }
        });
    }, [sticky]);
    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        (function() {
            var hm = document.createElement('script');
            hm.src = 'https://ssl.captcha.qq.com/TCaptcha.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(hm, s);
        })();
    }, []);

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <header className={`${headerStyles.header} ${sticky}`}>
            <span className={headerStyles.headerLogo}>
                <Icon type="iconjiankucunlogo" />
                简库存
            </span>
            {navs.map((nav, index) => (
                <Link
                    key={index}
                    to={nav.to}
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.active}
                >
                    {nav.text}
                </Link>
            ))}
            <span className={headerStyles.navItem}>
                APP下载
                <ul className={headerStyles.navItem__dialog}>
                    <li>
                        <p className={headerStyles.navItem__dialog__word}>
                            <Icon type="iconanzhuo" style={{ fontSize: '30px', color: '#888' }} />
                            <span>Android下载</span>
                        </p>
                        <p className={headerStyles.navItem__dialog__image}>
                            <img src={androidImage} alt="" wdith="100%" height="100%" />
                        </p>
                    </li>
                </ul>
            </span>
            <Button type="primary" style={{ margin: '0 20px' }} className={headerStyles.navButton}>
                <a target="_blank" rel="noopener noreferrer" href="//jkucun.com/">
                    登录
                </a>
            </Button>
            <Button type="primary" onClick={() => setShowModal(true)} className={headerStyles.navButton}>
                立即体验
            </Button>

            <Fixwindow />
            <ExperienceModal showModal={showModal} onCancel={handleCancel} />
        </header>
    );
};
